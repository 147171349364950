import HeaderComponent from "../component/header";
import FooterComponent from "../component/footer";
import CoverImg from "../assets/img/image-featured-a4DfvV.jpg";
import UserImg from "../assets/img/user/author-85z9UC.jpg";

const BlogItemPage = () => {
    return <>
        <HeaderComponent/>
        <main data-title="blog-single">
            <div className="uk-section uk-margin-small-top">
                <div className="uk-container">
                    <div className="uk-grid uk-flex uk-flex-center in-blog-1 in-article">
                        <div className="uk-width-1-1 in-figure-available">
                            <img className="uk-width-1-1 uk-border-rounded" src={CoverImg}
                                 alt="The typical U.S. household is spending $445 more a month due to inflation"/>
                        </div>
                        <div className="uk-width-3-4@m">
                            <article className="uk-card uk-card-default uk-border-rounded in-flat-rounded-top">
                                <div className="uk-card-body">
                                    <div className="uk-flex">
                                        <div className="uk-margin-small-right">
                                            <img className="uk-border-pill uk-background-muted"
                                                 src={UserImg} alt="author" width="32"
                                                 height="32"/>
                                        </div>
                                        <div className="uk-flex uk-flex-middle">
                                            <p className="uk-text-small uk-text-muted uk-margin-remove">
                                                Warren Wong
                                                <span className="uk-margin-small-left uk-margin-small-right">|</span>
                                            </p>
                                        </div>
                                        <div className="uk-flex uk-flex-middle">
                                            <p className="uk-text-small uk-text-muted uk-margin-remove">
                                                December 29, 2022
                                            </p>
                                        </div>
                                    </div>
                                    <h2 className="uk-margin-top uk-margin-medium-bottom">The typical U.S. household is
                                        spending $445 more a month due to inflation</h2>
                                    <p>Inflation is causing American households to spend $445 more per month buying the
                                        same items they did a year ago, according to an estimate from Moody’s
                                        Analytics.</p>
                                    <p>Consumer prices jumped by 8.2% in September versus the same month in 2021, the
                                        U.S. Bureau of Labor Statistics said Thursday. That rate is down from 9.1% in
                                        June, which marked the recent peak, but is still near the highest levels since
                                        the early 1980s.</p>
                                    <p>Wages for many workers haven’t kept pace with inflation, meaning they’ve lost
                                        purchasing power. Hourly earnings fell 3%, on average, in the year to September
                                        after accounting for inflation, according to the bureau.</p>
                                    <p>The inflation impact on households’ wallets isn’t uniform, though. Your personal
                                        inflation rate depends on the types of goods and services you buy, and other
                                        factors such as geography.</p>
                                    <h4 className="uk-margin-small-bottom">There’s ‘no one silver bullet’ to save
                                        money</h4>
                                    <p>Households can take certain steps to blunt the impact — and most are unlikely to
                                        feel good, according to financial advisors.</p>
                                    <p>“There’s no one silver bullet,” said Joseph Bert, a certified financial planner
                                        who serves as chairman and CEO of Certified Financial Group. The firm, based in
                                        Altamonte Springs, Florida, ranked No. 95 on the 2022 CNBC Financial Advisor 100
                                        list.</p>
                                    <p>Fixed expenses are outlays for essentials such as a mortgage, rent, food, transit
                                        costs and insurance, for example. Discretionary costs include spending on, say,
                                        dining out or vacations — things people enjoy but don’t necessarily need.</p>
                                    <p>There’s often less flexibility to cut fixed expenses, meaning nonessentials are
                                        the budget area where households likely have to make cuts if they want to save
                                        money, Maloon said.</p>
                                </div>
                                <div className="uk-card-footer uk-clearfix">
                                    <div className="uk-float-right in-article-share share-btn">
                                        <a style={{marginRight: "10"}} className="uk-label color-facebook" data-id="fb"
                                           data-sharebtn-ref="4">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                        <a style={{marginRight: "10"}} className="uk-label color-twitter" data-id="tw"
                                           data-sharebtn-ref="3">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                        <a style={{marginRight: "10"}} className="uk-label color-pinterest" data-id="pi"
                                           data-sharebtn-ref="2">
                                            <i className="fab fa-pinterest-p"></i>
                                        </a>
                                        <a className="uk-label color-email" data-id="mail" data-sharebtn-ref="1">
                                            <i className="fas fa-envelope"></i>
                                        </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <FooterComponent/>
    </>
}
export default BlogItemPage;