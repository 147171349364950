import HeaderComponent from "../component/header";
import FooterComponent from "../component/footer";
import SliderComponent from "../component/sliders";
import DownloadsComponent from "../component/downloads";
import ServiceComponent from "../component/services";
import BlogComponent from "../component/blogs";
import TrustedComponent from "../component/trusted";
import GlobalComponent from "../component/global";

const HomePage = () => {
    return <>
        <HeaderComponent/>
        <main>
            <SliderComponent/>
            <ServiceComponent/>
            <TrustedComponent/>
            <GlobalComponent/>
            <BlogComponent/>
            <DownloadsComponent/>
        </main>
        <FooterComponent/>
    </>
}
export default HomePage